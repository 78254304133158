@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply h-full w-full font-poppins;
}

#root {
  @apply 2xl:container 2xl:mx-auto;
}

.nav {
  transform: translateX(100%);
  transition: transform 0.5s;
}

.nav.click {
  transform: translateX(0);
}

.hero-animation {
  animation: heroAnimation 1.8s infinite;
}

.arrow-animation {
  animation: arrowAnimation 1s ease-in-out infinite;
}

.animation-button {
  transition: all 0.2s ease-in-out;
}

@keyframes heroAnimation {
  0%,
  100% {
    transform: translate(0, -20px);
  }

  50% {
    transform: translate(0px, 15px);
  }
}

@keyframes arrowAnimation {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, 0);
  }
}
